<template>
    <div>

        <div class="row" style="min-height: 100vh; width: 100%;background: #E9ecef;min-height: 100vh;">

            <!-- col 1 -->
            <div class="col-12 col-lg-3" style="background-color: #343a40;color:#CCCCCC;padding: 25px 40px;"
                data-aos-once="true" data-aos="slide-left" data-aos-duration="1500">

                <div class="text-center white--text">
                    <v-avatar size="100">
                        <img :src="$store.state.image" class="img-fluid" />
                    </v-avatar>

                    <h4 class="my-3 font-weight-bold">{{ $store.state.test.fullname }}</h4>
                    <h6 style="color:#FF5E21">{{ $store.state.test.email }}</h6>
                </div>

                <v-divider></v-divider>

                <!-- Total -->
                <div class="mb-3">
                    <h3 class="font-weight-bold">Total:</h3>
                    <h1 class="success--text my-2 font-weight-bold" v-if="score>=70">{{ score + "/100" }}</h1>
                    <h1 class="red--text my-2 font-weight-bold" v-else>{{ score + "/100" }}</h1>
                    <v-divider></v-divider>
                    <h5>By: {{ test.par }}</h5>
                </div>

                <div>
                    <v-btn large color="primary" @click="sendData()" v-if="showSendData">Submit Score</v-btn>
                </div>

            </div>

            <!-- col 2 -->
            <div class="grey lighten-3 col-12 col-lg-6" data-aos="fade-up-right" data-aos-duration="1500"
                data-aos-once="true" style="padding: 50px 40px;">

                <div class="row d-flex justify-center">

                    <div class="py-5 col-12 text-center">
                        <v-btn-toggle v-model="part" mandatory color="orange">
                            <v-btn @click="doIt(1)">
                                <h4>1</h4>
                            </v-btn>
                            <v-btn @click="doIt(2)">
                                <h4>2</h4>
                            </v-btn>
                            <v-btn @click="doIt(3)">
                                <h4>3</h4>
                            </v-btn>
                            <v-btn @click="doIt(4)">
                                <h4>4</h4>
                            </v-btn>
                            <v-btn @click="doIt(5)">
                                <h4>5</h4>
                            </v-btn>
                        </v-btn-toggle>

                        <v-divider></v-divider>
                    </div>

                    <!-- part 0 -->
                    <div class="col-12 text-center" v-if="part==0">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Narrative Composition:</span><br />
                                    Imagine you are stranded on a deserted island. Describe your experience, including
                                    how you got there, your emotions, and your strategies for survival. Consider
                                    elements such as setting, character development, and plot progression. Your response
                                    should be no less than 250 words.
                                </h1>
                                <textarea class="form-control text-h p-3" placeholder="Your answer here"
                                    style="margin: 10px 0px;" v-model="test.number1" rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number1<250">
                                    {{words.number1}}{{ words.number1 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number1}}{{ words.number1 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 1 -->
                    <div class="col-12 text-center" v-if="part==1">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Analytical Essay:
                                    </span><br />
                                    Analyze the theme of identity in a novel or film of your choice. Discuss how the
                                    characters' identities shape the plot and contribute to the overall message of the
                                    work. Provide specific examples and use critical analysis to support your points.
                                    Your response should be no less than 250 words.

                                </h1>
                                <textarea class="form-control text-h p-3" placeholder="Your answer here"
                                    style="margin: 10px 0px;" v-model="test.number2" rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number2<250">
                                    {{words.number2}}{{ words.number2 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number2}}{{ words.number2 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 2 -->
                    <div class="col-12 text-center" v-if="part==2">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Argumentative Essay:
                                    </span><br />
                                    Is technology more of a blessing or a curse in modern society? Take a stance and
                                    support your argument with evidence from various sources, including personal
                                    experience, statistics, and expert opinions. Address both the positive and negative
                                    impacts of technology on individuals and society as a whole. Your response should be
                                    no less than 250 words.
                                </h1>
                                <textarea class="form-control text-h p-3" placeholder="Your answer here"
                                    style="margin: 10px 0px;" v-model="test.number3" rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number3<250">
                                    {{words.number3}}{{ words.number3 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number3}}{{ words.number3 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 3 -->
                    <div class="col-12 text-center" v-if="part==3">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Literary Analysis:
                                    </span><br />
                                    Explore the symbolism of light and darkness in a poem or short story of your choice.
                                    Discuss how these elements contribute to the overall meaning and themes of the work.
                                    Consider the contrast between light and darkness, as well as any shifts in their
                                    significance throughout the text. Your response should be no less than 250 words.
                                </h1>
                                <textarea class="form-control text-h p-3" placeholder="Your answer here"
                                    style="margin: 10px 0px;" v-model="test.number4" rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number4<250">
                                    {{words.number4}}{{ words.number4 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number4}}{{ words.number4 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 4 -->
                    <div class="col-12 text-center" v-if="part==4">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Reflective Essay:
                                    </span><br />
                                    Reflect on a significant event or experience in your life that has shaped who you
                                    are today. Discuss the impact of this event on your personal growth, values, and
                                    worldview. Consider how it has influenced your goals and aspirations for the future.
                                    Your response should be no less than 250 words.
                                </h1>
                                <textarea class="form-control text-h p-3" placeholder="Your answer here"
                                    style="margin: 10px 0px;" v-model="test.number5" rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number5<250">
                                    {{words.number5}}{{ words.number5 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number5}}{{ words.number5 <= 1?' word' : ' words' }}</h4>
                            </div>
                        </form>
                    </div>


                    <div class="col-12 text-center p-0">

                        <div class="btn-group btn-group-lg" role="group">
                            <button class="btn" type="button" v-if="ShowPrevious" @click="Previous()"
                                style="color: #FF5E21;background: #FFFFFF;font-family: Raleway, sans-serif;">Previous
                            </button>

                            <button class="btn" type="button" @click="Next()" v-if="showNext"
                                style="background: #FF5E21;color: white;font-family: Raleway, sans-serif;">{{btnNext}}
                            </button>

                            <button class="btn" type="submit" v-if="showSubmit"
                                style="background: #FF5E21;color: white;font-family: Raleway, sans-serif;">Next
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            <!-- col 3 -->
            <div class="white col-12 col-lg-3 px-5 py-5" data-aos="slide-right" data-aos-duration="1500"
                data-aos-once="true">

                <div class="text-center">
                    <v-avatar size="100">
                        <img :src="require('@/images/umoja.jpeg')" class="img-fluid" />
                    </v-avatar>
                </div>

                <v-divider></v-divider>

                <!-- Number 1 -->
                <div>
                    <h5 class="font-weight-bold">Number 1 (out of 20):</h5>
                    <input class="form-control" type="number" v-model="points.number1" max="20" min="0" />
                    <h6 class="success--text my-2 font-weight-bold" v-if="points.number1==0 || points.number1==''"></h6>
                    <h6 class="success--text my-2 font-weight-bold" v-else-if="points.number1>=14">Success</h6>
                    <h6 class="red--text my-2 font-weight-bold" v-else>Failure</h6>
                    <v-divider></v-divider>
                </div>

                <!-- Number 2 -->
                <div>
                    <h5 class="font-weight-bold">Number 2 (out of 20):</h5>
                    <input class="form-control" type="number" v-model="points.number2" max="20" min="0" />
                    <h6 class="success--text my-2 font-weight-bold" v-if="points.number2==0 || points.number2==''"></h6>
                    <h6 class="success--text my-2 font-weight-bold" v-else-if="points.number2>=14">Success</h6>
                    <h6 class="red--text my-2 font-weight-bold" v-else>Failure</h6>
                    <v-divider></v-divider>
                </div>

                <!-- Number 3 -->
                <div>
                    <h5 class="font-weight-bold">Number 3 (out of 20):</h5>
                    <input class="form-control" type="number" v-model="points.number3" max="20" min="0" />
                    <h6 class="success--text my-2 font-weight-bold" v-if="points.number3==0 || points.number3==''"></h6>
                    <h6 class="success--text my-2 font-weight-bold" v-else-if="points.number3>=14">Success</h6>
                    <h6 class="red--text my-2 font-weight-bold" v-else>Failure</h6>
                    <v-divider></v-divider>
                </div>

                <!-- Number 4 -->
                <div>
                    <h5 class="font-weight-bold">Number 4 (out of 20):</h5>
                    <input class="form-control" type="number" v-model="points.number4" max="20" min="0" />
                    <h6 class="success--text my-2 font-weight-bold" v-if="points.number4==0 || points.number4==''"></h6>
                    <h6 class="success--text my-2 font-weight-bold" v-else-if="points.number4>=14">Success</h6>
                    <h6 class="red--text my-2 font-weight-bold" v-else>Failure</h6>
                    <v-divider></v-divider>
                </div>

                <!-- Number 5 -->
                <div>
                    <h5 class="font-weight-bold">Number 5 (out of 20):</h5>
                    <input class="form-control" type="number" v-model="points.number5" max="20" min="0" />
                    <h6 class="success--text my-2 font-weight-bold" v-if="points.number5==0 || points.number5==''"></h6>
                    <h6 class="success--text my-2 font-weight-bold" v-else-if="points.number5>=14">Success</h6>
                    <h6 class="red--text my-2 font-weight-bold" v-else>Failure</h6>
                    <v-divider></v-divider>
                </div>

                <!-- Total -->
                <div>
                    <h5 class="font-weight-bold">Total:</h5>
                    <h3 class="success--text my-2 font-weight-bold" v-if="total>=70">{{ total + "/100" }}</h3>
                    <h3 class="red--text my-2 font-weight-bold" v-else>{{ total + "/100" }}</h3>
                    <v-divider></v-divider>
                </div>


            </div>

        </div>

        <span class="d-none">{{ countTheWords }}</span>
        <!-- <span class="d-none">{{ computedCheckWindowOpener }}</span> -->

    </div>
</template>

<script>
    import firebase from "firebase";
    import db from "../db.js";

    export default {
        data() {
            return {

                toggle_exclusive: 0,
                test: {
                    number1: '',
                    number2: '',
                    number3: '',
                    number4: '',
                    number5: ''
                },
                words: {
                    number1: '',
                    number2: '',
                    number3: '',
                    number4: '',
                    number5: ''
                },
                points: {
                    number1: 0,
                    number2: 0,
                    number3: 0,
                    number4: 0,
                    number5: 0
                },
                score: 0,
                total: 0,


                part: 0,
                ShowPrevious: false,
                showNext: true,
                showSubmit: false,
                showSendData: true,
                btnNext: "Next",
                txtOther: true,
                errorMessage: '',


            }
        },
        created() {
            if (this.$store.state.ngayi.email == ''){
                this.$router.push("/login");
                return 0;
            }
            this.test = this.$store.state.test;
            this.points = {
                number1: this.test.score1,
                number2: this.test.score2,
                number3: this.test.score3,
                number4: this.test.score4,
                number5: this.test.score5
            };
            if (this.test.score == undefined) this.score = 0;
            else this.score = this.test.score;
        },
        computed: {
            countTheWords() {
                return this.countWords();
            }
            // computedCheckWindowOpener() {
            //     return this.checkWindowOpener();
            // }
        },
        methods: {
            isEmptyOrSpaces(str) {
                str = str.toString();
                return str === null || str.trim() === '';
            },
            isValidEmail(email) {
                // Regular expression for validating an email address
                var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            },
            isValidPhoneNumber(phoneNumber) {
                // Regular expression for validating a phone number in the format xxx-xxx-xxx
                var phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
                return phoneRegex.test(phoneNumber);
            },
            Next() {
                this.errorMessage = "";

                switch (this.part) {
                    case 0:

                        this.part = 1;
                        this.ShowPrevious = true;
                        break;
                    case 1:

                        this.part = 2;
                        this.ShowPrevious = true;
                        break;
                    case 2:

                        this.part = 3;
                        this.ShowPrevious = true;
                        break;
                    case 3:
                        this.part = 4;
                        this.ShowPrevious = true;
                        this.btnNext = "Next";
                        break;
                    case 4:
                        // alert("Send data");
                        this.showSubmit = false;
                        this.showNext = false;
                        break;
                }

                this.scrollUp();
            },
            Previous() {
                switch (this.part) {
                    case 4:
                        this.part = 3;
                        this.ShowPrevious = true;
                        this.showNext = true;
                        this.showSubmit = false;
                        this.btnNext = "Next";
                        break;
                    case 3:
                        this.part = 2;
                        this.ShowPrevious = true;
                        break;
                    case 2:
                        this.part = 1;
                        this.ShowPrevious = true;
                        break;
                    case 1:
                        this.part = 0;
                        this.ShowPrevious = true;
                        this.ShowPrevious = false;
                        break;

                    default:
                        break;
                }

                this.scrollUp();
            },
            sendData() {
                var that = this;
                try {
                    db.collection("quiz")
                        .doc(that.$store.state.test.id).update({
                            score: that.total,
                            score1: that.points.number1,
                            score2: that.points.number2,
                            score3: that.points.number3,
                            score4: that.points.number4,
                            score5: that.points.number5,
                            par: that.$store.state.ngayi.email,
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                        });

                    this.showSubmit = false;
                    this.showSendData = false;

                    setTimeout(() => {
                        this.$store.state.dejavu = null;
                        this.$router.push("/quiz");
                    }, 2000);

                } catch (error) {
                    // alert(error);
                    console.log(error);
                    that.errorMessage = error.message;
                    that.ShowPrevious = true;
                    that.showSendData = true;
                    that.showNext = false;
                    that.showSubmit = true;
                }
            },
            scrollUp() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth' // 'smooth' or 'auto'
                });
            },
            countTotal() {
                console.log("pasd");

                if (Number(this.points.number1 == undefined)) this.points.number1 = 0;
                if (Number(this.points.number2 == undefined)) this.points.number2 = 0;
                if (Number(this.points.number3 == undefined)) this.points.number3 = 0;
                if (Number(this.points.number4 == undefined)) this.points.number4 = 0;
                if (Number(this.points.number5 == undefined)) this.points.number5 = 0;

                if (Number(this.points.number1 > 20)) this.points.number1 = 20;
                if (Number(this.points.number2 > 20)) this.points.number2 = 20;
                if (Number(this.points.number3 > 20)) this.points.number3 = 20;
                if (Number(this.points.number4 > 20)) this.points.number4 = 20;
                if (Number(this.points.number5 > 20)) this.points.number5 = 20;

                this.total = Number(this.points.number1) + Number(this.points.number2) +
                    Number(this.points.number3) + Number(this.points.number4) +
                    Number(this.points.number5);
            },
            countWords() {

                this.countTotal();
                var str;
                var words;
                try {
                    // number 1;
                    str = this.test.number1.trim();
                    words = str.split(/\s+/);
                    this.words.number1 = words.length;
                    if (this.test.number1 == "") this.words.number1 = 0;

                    // number 2;
                    str = this.test.number2.trim();
                    words = str.split(/\s+/);
                    this.words.number2 = words.length;
                    if (this.test.number2 == "") this.words.number2 = 0;

                    // number 1;
                    str = this.test.number3.trim();
                    words = str.split(/\s+/);
                    this.words.number3 = words.length;
                    if (this.test.number3 == "") this.words.number3 = 0;

                    // number 1;
                    str = this.test.number4.trim();
                    words = str.split(/\s+/);
                    this.words.number4 = words.length;
                    if (this.test.number4 == "") this.words.number4 = 0;

                    // number 1;
                    str = this.test.number5.trim();
                    words = str.split(/\s+/);
                    this.words.number5 = words.length;
                    if (this.test.number5 == "") this.words.number5 = 0;
                } catch (error) {
                    return 0;
                }
            }
        }
    }
</script>

<style>

</style>